import React from "react";
import "../website/styles/home.css";
import shrilogo from "../assets/shriLogo.svg";
import phoneimg from "../assets/phone.svg";
import mailimg from "../assets/mail.svg";
import locationimg from "../assets/location.svg";

const Home = () => {
  return (
    <div className="home-background">
      <div className="home-footer-align">
        <div>
          <div className="centre-align shrilogo-res ">
            <img
              className="shrilogo"
              src={shrilogo}
              alt=";)"
              style={{ width: "200px" }}
            />
          </div>
          <div className="centre-align merienda-font home-heading-font">
            Savor the Moment
          </div>
          <div className="centre-align merienda-font home-heading-font-2">
            Where Every Bite Tells a Story
          </div>
          <div className="centre-align ">
            <p className="home-para poppins-font">
              Experience the vibrant flavours of a pure vegetarian haven. From
              the rich, authentic tastes of Odia and Bengali cuisine to North
              Indian classics, Jain specialties, comforting South Indian dishes,
              and nutritious, diet-friendly options — every dish celebrates
              taste, tradition, and wellness.
            </p>
          </div>
          <div className=" centre-align poppins-font">
            <p className="reserv-heading ">
              Reservations & Enquiries Made Easy!
            </p>
            <p className="home-para " style={{ marginTop: 3 }}>
              Reach out to any of the contacts below and let us create the
              perfect experience for you.
            </p>
          </div>
        </div>
        <div>
          <div
            className="
      home-footer res-gap poppins-font res-font-size"
            style={{
              marginTop: "2%",
              fontSize: "12px",
              gap: "50px",
            }}
          >
            <div
              className="home-footer res-phone"
              style={{ gap: "10px", marginLeft: "65px" }}
            >
              <a href="tel:+917200064077">
                <img
                  src={phoneimg}
                  alt="Mobile No"
                  style={{ height: "auto" }}
                />
              </a>
              <a
                style={{ color: "black", textDecoration: "none" }}
                href="tel:+917200064077"
                className="res-font-size"
              >
                +91 7200064077
              </a>
            </div>
            <div className="home-footer res-phone" style={{ gap: "10px" }}>
              <a href="mailto:business@shrijagannathpureveg.com">
                {" "}
                <img src={mailimg} alt="Mobile No" style={{ height: "auto" }} />
              </a>
              <a
                className="res-font-size"
                style={{ color: "black", textDecoration: "none" }}
                href="mailto:business@shrijagannathpureveg.com"
              >
                business@shrijagannathpureveg.com
              </a>
            </div>
            <div
              className="home-footer res-phone"
              style={{ width: "25vw", gap: "10px" }}
            >
              <img
                src={locationimg}
                alt="Mobile No"
                style={{ height: "auto" }}
              />
              <div className="res-font-size">
                26/5/4/B, Perumal Nagar, Balaji Nagar, Nanganallur, Chennai,
                Tamil Nadu 600061
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
